import React from 'react';
// import { Routes, Route } from "react-router-dom";
import "../src/styles/index.css";
import Navbar from './components/Navbar';
import Header from './components/Header';
// import HeaderTwo from './components/HeaderTwo';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';


function App() {
  return ( 
  <>
   <Navbar />
   <Header />
   <About/>
  
  <Projects/>
  
    <Skills/>
    <Contact />
    <Footer/>
   </>
  )
}

export default App;
