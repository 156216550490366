import React from 'react';
import { MdEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";


function Contact() {


    return (
        <section id="contact" className="contactSection">
          <div className="contact">
            <p className="title">Contact Me</p>
            <h1 className="subtitle">Lets Connect & Collab!</h1>
            <div className="contactContent">
              <div className="iconAndEmail">
                <MdEmail className="icon" />
                <p className="email">vinchymedia@gmail.com</p>
              </div>
              <div className="iconAndEmail">
                <IoLocationSharp className="icon-2" />
                <p className="location">New Jersey, USA</p>
              </div>
            </div>
          </div>
        </section>
      );
      
}


export default Contact;