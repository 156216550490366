import React from 'react';
import { IoOpen } from "react-icons/io5";
import projects from "./projects.json"

const Projects = () => {
    return (
        <section id="projects" className="projects">
       <div className="project-header">
            <p>PROJECTS</p>
            <h5 className="author">Designed and Developed by, Myself <br/> My Playground of Skills & Innovation </h5>
          </div>
        <div className="project-content" >
          {projects.map((projects, index) => (
          <div key={index} className="project-card">
            <div className="project-text">
              <h1 className="block-bank">{projects.name}</h1>
              <p>{projects.info}</p>
              <div className="project-card-sub-text">
              <p className="tech">{projects.tech}</p>
              <a href={projects.demo} target="_blank"  rel="noreferrer" className="demo"> LIVE DEMO <IoOpen/> </a>
              </div>
            </div>
            <div className="project-img">
              <img src={projects.img} alt="blockbank" />
            </div>
          </div>
          ))}
          </div>
        </section>
      );
}

export default Projects;
