import React from 'react';
import { Link } from 'react-scroll';

function Navbar() {
return (
<nav className="navbar">
        <div ><img className="logo" src="/vglogo.png" alt="Logo" />
        </div>
        <ul className="navLinks">
          <li><Link to="about" spy={true} smooth={true} offset={50} duration={500}>About</Link></li>
          <li><Link to="projects" spy={true} smooth={true} offset={10} duration={500}>Projects</Link></li>
          <li><Link to="contact" spy={true} smooth={true} offset={50} duration={500}>Contact</Link></li>
        </ul>
      </nav>

);
}

export default Navbar;