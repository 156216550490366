import React from 'react';




function Header() {
  
  

  
  
  return (
    <section className = "header" >
    <div className="header-hero">
      <div className="heading">
        <h1 className="headText">Hey, im Vin </h1>
        {/* <h1 className="bodyText"> React Dev </h1> */}
      </div>

      
      <svg className="wavesSVG" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
  <defs>
    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
  </defs>
  <g className="parallax">
    <use xlinkHref="#gentle-wave" x="50" y="0" fill="rgba(255,255,255,0.7)" />
    <use xlinkHref="#gentle-wave" x="50" y="3" fill="rgba(255,255,255,0.5)" />
    <use xlinkHref="#gentle-wave" x="50" y="5" fill="rgba(255,255,255,0.3)" />
    <use xlinkHref="#gentle-wave" x="50" y="7" fill="#fff" />
  </g>
</svg>
</div>
</section>

  );
}
  
  export default Header;