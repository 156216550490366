import React from "react";  
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

function Footer() {
    return (
        
        <section className="footer">
        <svg className="wavesSVGFoot" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
    <defs>
        <path id="gentle-wave" fill="rgba(84, 89, 255, 0.9)" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    </defs>
    <g className="parallax">
        <use xlinkHref="#gentle-wave" x="50" y="0" fill="rgba(121, 151, 250,1)" />  {/* Blue wave with 90% opacity */}
        <use xlinkHref="#gentle-wave" x="50" y="3" fill="rgba(121, 151, 250,0.7)" />  {/* Blue wave with 60% opacity */}
        <use xlinkHref="#gentle-wave" x="50" y="5" fill="rgba(121, 151, 250,0.6)" />  {/* Blue wave with 50% opacity */}
        <use xlinkHref="#gentle-wave" x="50" y="7" fill="rgba(121, 151, 250)" />    {/* Blue wave with 100% opacity */}
    </g>
</svg>

     <div className="footer-container">
     <a className="link" href="https://github.com/vgalante2">
      <FaGithub className="github" />
     </a>
    <p className="copyright"> copyright © cenzo </p>
    <a className="link" href="https://www.linkedin.com/in/vincent-galante-14b4a7126/">
    <FaLinkedin  className="linkedin" />
    </a>
    </div>
</section>
    );
}




export default Footer;