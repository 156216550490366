import React from "react";
import { IoLogoPython } from "react-icons/io5";
import { FaJs } from 'react-icons/fa6';
import { DiHtml5, DiReact, DiNodejs, DiSass, DiMongodb } from "react-icons/di";
import { BiLogoPostgresql } from "react-icons/bi";
import { SiNextdotjs } from "react-icons/si";



function Skills() {
return  ( 
<div className="skills"> 
<p className="skill-heading">tech stack:</p>
<div className="skills-container"> 
<p className="skill-icon"> <DiHtml5 className="html" /></p>
 <p className="skill-icon"><DiSass className="sass"/></p>
 <p className="skill-icon"><FaJs className="js" /></p>
 <p className="skill-icon"><IoLogoPython className="python" /></p>
 <p className="skill-icon"><DiReact className="react"/></p>
 <p className="skill-icon"><SiNextdotjs className="next"/></p>
 <p className="skill-icon"><DiNodejs className="node"/></p> 
 <p className="skill-icon"><DiMongodb className="mongodb"/></p>
 <p className="skill-icon"><BiLogoPostgresql className="postgres"/></p>
 </div>
</div>
)
}

export default Skills;