import React, { useEffect} from 'react';
import { FaBitcoin, FaFootball, FaHockeyPuck, FaEthereum} from "react-icons/fa6";
import { SiRipple } from "react-icons/si";
import Typed from 'typed.js';


function About() {
    
    
    // const goTo = () => {
    //     window.scrollTo({
    //       top: (0,0),
    //       behavior: "smooth"
    //     });
    //    }

    useEffect(() => {
        // Initialize Typed.js
        const typed = new Typed(".auto-type", {
            strings: [ "Developer", "Marketer", "web3 Enthusiast"],
            typeSpeed: 150,
            backSpeed: 50,
            loop: true,
            startDelay: 500,
        });

        // Clean up
        return () => {
            typed.destroy();
        };
    }, []);
    
    return (
           <section  id="about" className="aboutSection" >
            <div className="card">
                {/* <p className="cardHeading">About Me</p> */}
                <div className="changingHeading">
                <h1 className="Im_a">Im a <span className="changed_text auto-type"></span> </h1>
                </div>
                <div className="iconSection">
                    <span className="icon"><i class="fas fa-icon1"></i><FaBitcoin className="btc" /></span>
                    <span className="icon"><i class="fas fa-icon2"></i><FaFootball className="football" /></span>
                    <span className="icon"><i class="fas fa-icon3"></i><FaEthereum className="eth" /></span>
                    <span className="icon"><i class="fas fa-icon4"></i><FaHockeyPuck className="hockey" /></span>
                    <span className="icon"><i class="fas fa-icon5"></i><SiRipple className="ripple" /></span>
                </div>
                <p className="cardParagraph" >As a former digital marketing specialist transitioning into Development, I have accumulated a wide range of skills, including technologies such as HTML5, CSS, Javascript, Node.js, Express, React/Next.js, Git, NoSQL, SQL, and APIs. I developed a keen eye for immaculate design along with maintaining responsiveness. My expertise lies in creating dynamic engaging interfaces through writing clean and clear code while staying up-to-date with the latest tech. Im currently learning Machine Learning</p>
            </div>
        </section>
    );
}




export default About;